import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const IndexPage = () => {
    return (
        <Layout pageTitle="Tribe Night- Press Releases">
            <Row>
                <Col>
                    <Link to="/events/tribe-night">Back</Link>
                </Col>
            </Row>
            

            <ValueRow
                name=""
                href=""
            />
            
        </Layout>
    )
}

const ValueRow = ({ name, href }) => {
    return (
        <Row className="mt-5">
            <Col>
                <h3>{name}</h3>
                <a
                    href={href}
                    className="btn btn-primary m-3"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Download Document
                </a>
            </Col>
        </Row>
    )
}

export default IndexPage
